/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import feedApi from "~/api/feed";
import ROUTE from "~/constants/routes";
import router from "~/router";
import moment from "moment";

Vue.use(Vuex);

const state = {
  feeds: {},
  feedsList: [],

  flats: {},
  flatsList: [],

  complexes: {},
  complexesList: [],

  error: {},
  validationErrors: {},
  loadingProcess: false,
  successRegistration: false,
};

const mutations = {
  setError: (state, payload) => {
    state.error = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
  setValidationErrors: (state, payload) => {
    state.validationErrors = payload;
  },

  setFeedsList: (state, payload) => {
    state.feedsList = payload;
  },
  setFeeds: (state, payload) => {
    state.feeds = payload;
  },

  setComplexesList: (state, payload) => {
    state.complexesList = payload;
  },
  setComplexes: (state, payload) => {
    state.complexes = payload;
  },

  setFlatsList: (state, payload) => {
    state.flatsList = payload;
  },
  setFlats: (state, payload) => {
    state.flats = payload;
  },
};

const actions = {
  getFeedsList({ commit }, payload) {
    commit("setLoading", true);
    feedApi
      .getFeedList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const feeds = res.data.data;
        for (let i = 0; feeds.length > i; i++) {
          feeds[i].statusName = feeds[i].status.name;
        }
        commit("setFeedsList", feeds);
        commit("setFeeds", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createFeed({ commit }, payload) {
    commit("setLoading", true);
    feedApi
      .createFeed(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.FEED_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editFeed({ commit }, { url, editData }) {
    commit("setLoading", true);
    feedApi
      .editFeed(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.FEED_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  //Complex

  getComplexList({ commit }, payload) {
    commit("setLoading", true);
    feedApi
      .getComplexList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const complex = res.data.data;
        for (let i = 0; complex.length > i; i++) {
          complex[i].created_at = moment(complex[i].createdAt).format("DD.MM.YYYY HH:mm");
          complex[i].is_custom = complex[i].isCustom ? "Создан вручную" : "Создан с фида";
        }
        commit("setComplexesList", complex);
        commit("setComplexes", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createComplex({ commit }, payload) {
    commit("setLoading", true);
    feedApi
      .createComplex(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.COMPLEX_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editComplex({ commit }, { url, editData }) {
    commit("setLoading", true);
    feedApi
      .editComplex(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.COMPLEX_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  //Flat

  getFlatsList({ commit }, payload) {
    commit("setLoading", true);
    feedApi
      .getFlatList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const flats = res.data.data;
        for (let i = 0; flats.length > i; i++) {
          flats[i].status_flat = flats[i].status.name;

          if (flats[i].video.status) {
            if (flats[i].video.status == "failed") {
              flats[i].video_url = flats[i].video.error.detail;
              flats[i].video_date = null;
              flats[i].video_credit = null;
              flats[i].video_count_generate = null;
            } else if (flats[i].video.status == "waiting") {
              flats[i].video_url = "Ожидание";
              flats[i].video_date = null;
              flats[i].video_credit = null;
              flats[i].video_count_generate = null;
            } else if (flats[i].video.status == "processing") {
              flats[i].video_url = "В работе";
              flats[i].video_date = null;
              flats[i].video_credit = null;
              flats[i].video_count_generate = null;
            } else if (flats[i].video.status == "completed") {
              flats[i].video_url = flats[i].video.storageVideoUrl;
              flats[i].video_date = moment(flats[i].video.updatedAt).format("DD.MM.YYYY HH:mm");
              let count = 0;
              let credit = 0;
              const credit_usage_logs = flats[i].video.creditUsageLogs;
              for (let e = 0; credit_usage_logs.length > e; e++) {
                count = count + 1;
                credit = credit + +credit_usage_logs[i].countCreditsWrittenOff;
              }
              flats[i].video_credit = credit;
              flats[i].video_count_generate = count;
            }
          } else {
            flats[i].video_url = null;
            flats[i].video_date = null;
            flats[i].video_credit = null;
            flats[i].video_count_generate = null;
          }

          flats[i].external_id = flats[i].externalId;
          flats[i].created_at = moment(flats[i].createdAt).format("DD.MM.YYYY HH:mm");
          flats[i].updated_at = moment(flats[i].updatedAt).format("DD.MM.YYYY HH:mm");
        }
        commit("setFlatsList", flats);
        commit("setFlats", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const getters = {};

const feed = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default feed;
