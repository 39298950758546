export default {
  LOGIN_PAGE: "/login",
  REGISTRATION_PAGE: "/registration",
  HOME_PAGE: "/",
  LOGS_PAGE: "/logs",
  STREAM_PAGE: "/stream",
  DEMO_PAGE: "/demo",
  ERROR_AUTH: "/auth-error",

  TELEGRAM_GK_SAMOLET: "/telegram/ai_intercom_gk_samolet_dev_bot",

  // Users
  //

  USERS_LIST: "/users/list/:page",
  USER_CREATE: "/user/create",
  USER_EDIT: "/user/edit/:id",
  USER_DETAIL: "/user/detail/:id",

  // Contact
  //

  CONTACT_LIST: "/contact/list/:page",
  CONTACT_CREATE: "/contact/create",
  CONTACT_EDIT: "/contact/edit/:id",

  // Feed
  //

  FEED_LIST: "/feed/list/:page",
  FEED_CREATE: "/feed/create",
  FEED_EDIT: "/feed/edit/:id",

  // Flat
  //

  FLAT_LIST: "/feed/:feedId/flat/list/:page",
  FLAT_DETAIL: "/flat/:id",

  // Flat
  //

  FLAT_LIST: "/feed/:feedId/flat/list/:page",
  FLAT_DETAIL: "/flat/:id",

  // Complex
  //

  COMPLEX_LIST: "/complex/list/:page",
  COMPLEX_CREATE: "/complex/create",
  COMPLEX_EDIT: "/complex/edit/:id",
};
