import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  getFeedList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.FEED + url,
      })
      .then((res) => res.data);
  },

  createFeed(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.FEED,
        data,
      })
      .then((res) => res.data);
  },

  editFeed(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.FEED + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemFeed(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.FEED + url,
      })
      .then((res) => res.data);
  },

  deleteFeed(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.FEED + url,
      })
      .then((res) => res.data);
  },

  getFeedFormats() {
    return callApi
      .request({
        method: "GET",
        url: URL.FEED_FORMAT,
      })
      .then((res) => res.data);
  },

  //Flat

  getFlatList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.FLAT + url,
      })
      .then((res) => res.data);
  },

  getItemFlat(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.FLAT + url,
      })
      .then((res) => res.data);
  },

  getFlatVideo(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.FLAT_VIDEO + url,
      })
      .then((res) => res.data);
  },

  //Complex

  getComplexList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.COMPLEX + url,
      })
      .then((res) => res.data);
  },

  createComplex(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.COMPLEX,
        data,
      })
      .then((res) => res.data);
  },

  editComplex(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.COMPLEX + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemComplex(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.COMPLEX + url,
      })
      .then((res) => res.data);
  },
};
