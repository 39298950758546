import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  login(data) {
    return callApi.request({
      method: "POST",
      url: URL.LOG_IN,
      data,
    });
  },

  registration(data) {
    return callApi.request({
      method: "POST",
      url: URL.REGISTRATION,
      data,
    });
  },

  getRegistrationField() {
    return callApi.request({
      method: "GET",
      url: URL.REGISTRATION,
    });
  },

  getProfile() {
    return callApi.request({
      method: "POST",
      url: URL.GET_PROFILE,
    });
  },

  logout() {
    return callApi.request({
      method: "POST",
      url: URL.LOG_OUT,
    });
  },

  getUsersList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.USER + url,
      })
      .then((res) => res.data);
  },

  createUser(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.USER,
        data,
      })
      .then((res) => res.data);
  },

  editUser(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.USER + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemUser(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.USER + url,
      })
      .then((res) => res.data);
  },

  getItemDeveloper(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.DEVELOPER + url,
      })
      .then((res) => res.data);
  },

  deleteUser(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.USER + url,
      })
      .then((res) => res.data);
  },

  //contact

  getContactList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.CONTACT + url,
      })
      .then((res) => res.data);
  },

  createContact(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.CONTACT,
        data,
      })
      .then((res) => res.data);
  },

  editContact(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.CONTACT + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemContact(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.CONTACT + url,
      })
      .then((res) => res.data);
  },

  deleteContact(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.CONTACT + url,
      })
      .then((res) => res.data);
  },
};
