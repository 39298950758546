import Vue from "vue";
import VueRouter from "vue-router";
import ROUTE from "~/constants/routes";
import { getStoredUserInfo } from "../helpers/userAuth";

const Login = () => import("~/pages/Login.vue");
const Registration = () => import("~/pages/Registration.vue");
const Home = () => import("~/pages/Home.vue");
const Logs = () => import("~/pages/Logs.vue");
const Stream = () => import("~/pages/Stream.vue");
const Demo = () => import("~/pages/StreamDemo.vue");
const PageNotFound = () => import("~/pages/error/PageNotFound.vue");

const UsersList = () => import("~/pages/users/item/usersList.vue");
const UserCreate = () => import("~/pages/users/item/userCreate.vue");
const UserEdit = () => import("~/pages/users/item/userEdit.vue");

const ContactList = () => import("~/pages/contact/list.vue");
const ContactCreate = () => import("~/pages/contact/create.vue");
const ContactEdit = () => import("~/pages/contact/edit.vue");

const FeedList = () => import("~/pages/feed/list.vue");
const FeedCreate = () => import("~/pages/feed/create.vue");
const FeedEdit = () => import("~/pages/feed/edit.vue");

const ComplexList = () => import("~/pages/complex/list.vue");
const ComplexCreate = () => import("~/pages/complex/create.vue");
const ComplexEdit = () => import("~/pages/complex/edit.vue");

const FlatList = () => import("~/pages/flat/list.vue");
const FlatDetail = () => import("~/pages/flat/detail.vue");

const TelegramGkSamolet = () => import("~/pages/telegram/GKSamolet.vue");


Vue.use(VueRouter);

const checkPrivateRoute = (to, from, next) => {
  if (getStoredUserInfo() === null) return next(ROUTE.LOGIN_PAGE);
  return next();
};

const adminPrivateRoute = (to, from, next) => {
  // console.log(getStoredUserInfo());
  // if (getStoredUserInfo().group[0].id !== 2) return next(ROUTE.LOGIN_PAGE);
  return next();
};

const routes = [
  {
    path: ROUTE.LOGIN_PAGE,
    name: "Login",
    component: Login,
  },

  {
    path: ROUTE.REGISTRATION_PAGE,
    name: "Registration",
    component: Registration,
  },

  // Home page

  {
    path: ROUTE.HOME_PAGE,
    name: "HOME",
    component: Home,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
    },
  },

  // Logs page

  {
    path: ROUTE.LOGS_PAGE,
    name: "LOGS",
    component: Logs,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
    },
  },

  // Stream page

  {
    path: ROUTE.STREAM_PAGE,
    name: "STREAM",
    component: Stream,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
    },
  },

  // Demo page

  {
    path: ROUTE.DEMO_PAGE,
    name: "DEMO",
    component: Demo,
  },

  // Users Pages

  {
    path: ROUTE.USERS_LIST,
    name: "UsersList",
    component: UsersList,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },
  {
    path: ROUTE.USER_CREATE,
    name: "UserCreate",
    component: UserCreate,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },
  {
    path: ROUTE.USER_EDIT,
    name: "UserEdit",
    component: UserEdit,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },

  // Contact Pages

  {
    path: ROUTE.CONTACT_LIST,
    name: "ContactList",
    component: ContactList,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },
  {
    path: ROUTE.CONTACT_CREATE,
    name: "ContactCreate",
    component: ContactCreate,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },
  {
    path: ROUTE.CONTACT_EDIT,
    name: "ContactEdit",
    component: ContactEdit,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },

  // Feed Pages

  {
    path: ROUTE.FEED_LIST,
    name: "FeedList",
    component: FeedList,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },
  {
    path: ROUTE.FEED_CREATE,
    name: "FeedCreate",
    component: FeedCreate,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },
  {
    path: ROUTE.FEED_EDIT,
    name: "FeedEdit",
    component: FeedEdit,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },

  // Complex Pages

  {
    path: ROUTE.COMPLEX_LIST,
    name: "ComplexList",
    component: ComplexList,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },
  {
    path: ROUTE.COMPLEX_CREATE,
    name: "ComplexCreate",
    component: ComplexCreate,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },
  {
    path: ROUTE.COMPLEX_EDIT,
    name: "ComplexEdit",
    component: ComplexEdit,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },

  // Flat Pages

  {
    path: ROUTE.FLAT_LIST,
    name: "FlatList",
    component: FlatList,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },

  {
    path: ROUTE.FLAT_DETAIL,
    name: "FlatDetail",
    component: FlatDetail,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },

  {
    path: ROUTE.TELEGRAM_GK_SAMOLET,
    name: "TelegramGkSamolet",
    component: TelegramGkSamolet,
  },

  // 404 routes

  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",

  base: process.env.BASE_URL,

  routes,
});

export default router;
