import Vue from "vue";
import Vuex from "vuex";

import users from "./users";
import feed from "./feed";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    users,
    feed,
  },
});
